import React from "react"
import styled from "styled-components"
import Image from "./image"

const LogoContainer = styled.div`
  width: 151px;
  height: 62px;
`

const SiteLogo = () => (
  <LogoContainer>
    <Image source="logo.png" alt="Logo - Investitori Ribelli" />
  </LogoContainer>
)

export default SiteLogo
