import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const RemoteImage = styled.img`
  margin: 0;
  padding: 0;
  display: block;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center top;
`

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */
const Image = props => {
  const { alt, source, className, style, width, height } = props
  const isURL = typeof source === "string" && source.startsWith("http")
  if (isURL) {
    return (
      <RemoteImage className={className} style={style} alt={alt} src={source} />
    )
  }
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  fluid(maxWidth: 5000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const image = data.images.edges.find(n => {
          return n.node.relativePath.includes(source)
        })
        if (!image) {
          return null
        }
        const fluid = image.node.childImageSharp.fluid
        if (width && height) {
          return (
            <div style={{ width, height }}>
              <Img
                className={className}
                alt={alt}
                fluid={fluid}
                imgStyle={style}
              />
            </div>
          )
        } else {
          return (
            <Img
              className={className}
              alt={alt}
              fluid={fluid}
              imgStyle={style}
            />
          )
        }
      }}
    />
  )
}

export default Image
