/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Header from "./header"
import Footer from "./footer"

import "./layout.css"
import "./typography.css"
import "./fontawesome.css"

const SiteContent = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Main = styled.main`
  flex-grow: 1;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const Layout = ({ children, style, heroContent = null }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          navigationLinks {
            title
            path
          }
        }
      }
    }
  `)
  const { title: siteTitle, navigationLinks } = data.site.siteMetadata
  return (
    <SiteContent id="site-container">
      <Header siteTitle={siteTitle} links={navigationLinks} />
      {heroContent}
      <Main style={style}>{children}</Main>
      <Footer />
    </SiteContent>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
