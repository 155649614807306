import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

import SocialLinks from "./social-links"
import SiteLogo from "./site-logo"

const FooterContainer = styled.footer`
  color: #333333;
  background-color: #fafafa;

  p {
    font-size: 80%;
    text-align: center;
    line-height: 1.5;
  }
`

const FooterBar = styled.div`
  max-width: 600px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div,
  p {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const SiteSocialLinks = () => {
  return (
    <StaticQuery
      query={graphql`
        query SocialMediaQuery {
          site {
            siteMetadata {
              socialLinks: social {
                name
                url
                icon
                color
              }
            }
          }
        }
      `}
      render={data => {
        const { socialLinks } = data.site.siteMetadata
        return <SocialLinks links={socialLinks} appearance="round" />
      }}
    />
  )
}

const Footer = () => {
  return (
    <FooterContainer>
      <FooterBar>
        <SiteLogo />
        <SiteSocialLinks />
        <p>© {new Date().getFullYear()} Cocoa Beans Software Ltd</p>
        <p>
          <em>Investitori Ribelli</em> non offre servizi di consulenza
          finanaziaria. Tutti gli argomenti trattati sono da considerarsi
          contenuti generali a scopo educativo e informativo. Pertanto non
          costituiscono, nè sostituiscono, in alcun modo la consulenza di un
          esperto finanziario.
        </p>
      </FooterBar>
    </FooterContainer>
  )
}

export default Footer
