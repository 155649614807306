/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function normalizedURL(url) {
  const lastChar = url.substr(url.length - 1)
  if (lastChar === "/") {
    return url.slice(0, -1)
  }
  return url
}

function SEO({
  lang,
  meta = [],
  title,
  description,
  slug,
  imageURL,
  marketingImageURL,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteURL: siteUrl
            title
            description
            author
            keywords
            fbPageID
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const siteURL = normalizedURL(site.siteMetadata.siteURL)
  const sharingImageURL = `${siteURL}${marketingImageURL || imageURL}`
  const pageURL = `${siteURL}${slug}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      defaultTitle={site.siteMetadata.title}
      meta={[
        {
          name: `language`,
          content: lang,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: site.siteMetadata.keywords.join(", "),
        },
        {
          property: `fb:page_id`,
          content: site.siteMetadata.fbPageID,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: sharingImageURL,
        },
        {
          property: `og:image:alt`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: pageURL,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:image`,
          content: sharingImageURL,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
