import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const LinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  .social-link {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`

const SocialLink = ({ link, appearance }) => {
  const needsCircle = appearance === "round"
  let content = <i className={`fab fa-${link.name}`}></i>
  let icon = link.icon || link.name
  if (needsCircle) {
    content = (
      <span className="fa-stack">
        <i className="fas fa-circle fa-stack-2x"></i>
        <i className={`fab fa-${icon} fa-stack-1x fa-inverse`}></i>
      </span>
    )
  }

  const style = link.color ? { color: link.color } : {}
  return (
    <a className="social-link" alt={link.name} href={link.url} style={style}>
      {content}
    </a>
  )
}

const SocialLinks = ({ links, alignment = "center", appearance }) => (
  <LinksContainer style={{ justifyContent: alignment }}>
    {links.map(link => (
      <SocialLink key={link.name} link={link} appearance={appearance} />
    ))}
  </LinksContainer>
)

SocialLinks.defaultProps = {
  links: [],
  appearance: "simple",
}

SocialLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, url: PropTypes.string })
  ),
  appearance: PropTypes.oneOf(["simple", "round"]),
}

export default SocialLinks
