import PropTypes from "prop-types"
import React, { Fragment } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import SiteLogo from "./site-logo"

const NavBar = styled.header`
  color: #333333;
`

const NavContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;

  h1 {
    color: inherit;
    font-size: 28px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

const NavBarLogoArea = styled.div``

const NavBarLinksArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    list-style: none;
    margin: 0;

    li {
      display: inline-block;
      margin-right: 3rem;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }

      & a {
        padding: 3px 0;
      }
    }
  }
`

const MainNavLinks = styled.ul`
  @media (max-width: 960px) {
    display: none;
  }
`

const ResponsiveNavLinksContainer = styled.div`
  display: none;

  & .nav-links {
    display: none;
  }

  &:active .nav-links {
    display: block;
  }

  @media (max-width: 960px) {
    display: block;
  }
`

const ResponsiveNavLinksToggle = styled.button`
  color: #505050;
  background: #ffffff;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  padding: 8px 20px;
  transition: all 300ms ease-in-out;
`

const ResponsiveNavLinksArea = styled.div`
  display: none;
  padding: 64px;
  margin-top: 94px;
  align-items: start;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  position: absolute;

  background: white;

  &.active {
    display: flex;
  }
`

const ResponsiveNavLinksList = styled.ul`
  display: grid;
  grid-gap: 32px;
  justify-items: start;
  justify-content: center;
`

const NavLinks = ({ links }) => {
  return (
    <Fragment>
      {links.map(link => (
        <li key={link.title}>
          <Link to={link.path} activeClassName="active" partiallyActive>
            {link.title}
          </Link>
        </li>
      ))}
    </Fragment>
  )
}

const ResponsiveNavLinks = ({ links, isActive, toggleMenu }) => (
  <ResponsiveNavLinksContainer>
    <ResponsiveNavLinksToggle onClick={toggleMenu}>
      <i className={`fas ${isActive ? "fa-times" : "fa-bars"}`} />
    </ResponsiveNavLinksToggle>
    <ResponsiveNavLinksArea className={isActive ? "active" : null}>
      <ResponsiveNavLinksList className="text-large">
        <NavLinks links={links} />
      </ResponsiveNavLinksList>
    </ResponsiveNavLinksArea>
  </ResponsiveNavLinksContainer>
)

const NavSiteLogo = () => (
  <Link to="/">
    <SiteLogo />
  </Link>
)

export default class Header extends React.Component {
  static propTypes = {
    links: PropTypes.arrayOf(
      PropTypes.shape({ title: PropTypes.string, path: PropTypes.string })
    ),
  }

  static defaultProps = {
    links: [],
  }

  state = {
    responsiveLinksVisible: false,
  }

  toggleResponsiveNavLinkMenu = () => {
    const { responsiveLinksVisible } = this.state
    this.setState({
      ...this.state,
      responsiveLinksVisible: !responsiveLinksVisible,
    })
  }

  render() {
    const { responsiveLinksVisible } = this.state
    const { links } = this.props
    return (
      <NavBar>
        <NavContainer>
          <NavBarLogoArea>
            <NavSiteLogo />
          </NavBarLogoArea>
          <NavBarLinksArea>
            <MainNavLinks>
              <NavLinks links={links} />
            </MainNavLinks>
            <ResponsiveNavLinks
              links={links}
              isActive={responsiveLinksVisible}
              toggleMenu={this.toggleResponsiveNavLinkMenu}
            />
          </NavBarLinksArea>
        </NavContainer>
      </NavBar>
    )
  }
}
