import React from "react"
import Layout from "./layout"
import SEO from "./seo"

const Page = props => {
  const {
    children,
    description,
    heroContent,
    imageURL,
    marketingImageURL,
    meta,
    slug,
    style,
    title,
  } = props
  return (
    <Layout style={style} heroContent={heroContent}>
      <SEO
        title={title}
        description={description}
        slug={slug}
        imageURL={imageURL}
        marketingImageURL={marketingImageURL}
        meta={meta}
        lang="it"
      />
      {children}
    </Layout>
  )
}

export default Page
